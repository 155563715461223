import React, { Component, Fragment } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid';

import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react/select-box';
import moment from 'moment';
import { DashboardComponent } from '../components/DashboardComponent';
import { getRequestParams, formatDate } from '../../src/helpers/globalfunctions.js';
import secureLocalStorage from 'react-secure-storage';

const position = { of: '#historydiv' };

const currentDateTime  = new Date();
export class YatriLogComponent extends Component {
    static displayName = YatriLogComponent.name;
    
    constructor(props) {
        super(props);
        this.state = {
            currentTime: moment().format("HH:mm A"),
            dataSource: [],
            accountId: secureLocalStorage.getItem("session_accountId"),
            locationId: secureLocalStorage.getItem("session_locationId"),
            userId: secureLocalStorage.getItem("session_userId"),
            isSubmited: false,
            loadPanelVisible: false,
            dataSourceYatriApiLog: [],
            vehicleList: [],
            vehicleId: 0,
            startDate: moment(currentDateTime).format("MM/DD/YYYY") + " 00:00:00",
            endDate: moment(currentDateTime).format("MM/DD/YYYY") + " 23:59:59",
            errors: {
                trainNumber: '',
                vehicleId: 0,
                dateRange: ''
            },
        };
    }

    componentDidMount() {
        this.getVehicles();
    }

    componentWillUnmount() {
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        this.state.errors[name] = "";
    }

    getYatriApiLog = async () => {
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                let request = {};
                let startDateTime = formatDate(this.state.startDate, "yyyy-MM-dd HH:mm:ss");
                let endDateTime = formatDate(this.state.endDate, "yyyy-MM-dd HH:mm:ss");
                let endTimeDate = moment(endDateTime).add(59, 'second')
                request.VehicleId = this.state.vehicleId;
                request.StartDateTime = startDateTime;
                request.EndDateTime = formatDate(endTimeDate, "yyyy-MM-dd HH:mm:ss");
                const requestParams = getRequestParams('POST', request);
                this.setState({ loadPanelVisible: true });
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + "TrainData/GetYatriApiLog", requestParams);
                const data = await response.json();
                this.setState({
                    dataSourceYatriApiLog: data.payload,
                    isSubmited: false
                });
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".getYatriApiLog function", err);
            this.setState({ loadPanelVisible: false });
        }
        this.setState({ loadPanelVisible: false });
    }
    getVehicles = async () => {
        try {
            const requestParams = getRequestParams('GET');
            let userId = secureLocalStorage.getItem('session_userId');
            let accountId = secureLocalStorage.getItem('session_accountId');
            let locationId = secureLocalStorage.getItem('session_locationId');
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'Vehicles/DropdownList/' + userId + "/" + accountId + "/" + locationId, requestParams);
            const data = await response.json();
            this.setState({
                vehicleList: data.payload,
            });

        } catch (err) {
            console.log("Error in " + this.displayName + ".getVehicles function", err);
            this.setState({ loadPanelVisible: false });
        }
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.vehicleId = "";
            errors.dateRange = "";

            if (parseInt(this.state.vehicleId) == 0) {
                formIsValid = false;
                errors.vehicleId = "Please select vehicle .";
            }
            let startDateTime = formatDate(this.state.startDate, "yyyy-MM-dd HH:mm:ss");
            let endDateTime = formatDate(this.state.endDate, "yyyy-MM-dd HH:mm:ss");
            if (startDateTime > endDateTime) {
                formIsValid = false;
                errors.dateRange = "From date should be greater then to date.";
            }
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    // handleResetStateData = (e) => {
    //     this.setState({
    //         isSubmited: false,
    //         loadPanelVisible: false,
    //         vehicleId: 0,
    //         startDate: moment(currentDateTime).format("MM/DD/YYYY") + " 00:00",
    //         endDate: moment(currentDateTime).format("MM/DD/YYYY") + " 23:59",
    //         startDateForTrainMapped: moment(currentDateTime).format("MM/DD/YYYY") + " 00:00",
    //         endDateForTrainMapped: moment(currentDateTime).format("MM/DD/YYYY") + " 23:59",
    //         dataSourceYatriApiLog: [],
    //         dataSourceTrainMappingReport: [],
    //         errors: {
    //             trainNumber: '',
    //             vehicleId: 0,
    //             dateRange: ''
    //         }
    //     });
    // }

    handleOnFilteFromDateChanged = (date) => {
        this.setState({ startDate: date.value });
        let errors = this.state.errors;
        errors.dateRange = "";
    };
    handleOnFilteToDateChanged = (date) => {
        this.setState({ endDate: date.value });
        let errors = this.state.errors;
        errors.dateRange = "";
    };
    handleOnFilterVehicleChange = (e) => {
        if (e.selectedItem !== null) {
            this.setState({ vehicleId: (e.selectedItem != null ? e.selectedItem.vehicleId : 0) });
        }
        else {
            this.setState({ vehicleId: (e.selectedItem != null ? e.selectedItem.vehicleId : 0) });
            this.setState({ dataSource: [] })
        }
        let errors = this.state.errors;
        errors.vehicleId = "";
        this.setState({ errors: errors });
    }

    setToday = async (e) => {
        e.preventDefault();
        this.setState({
            startDate: moment(currentDateTime).format("MM/DD/YYYY") + " 00:00:00",
            endDate: moment(currentDateTime).format("MM/DD/YYYY") + " 23:59:59",
        })
    }

    setYesterday = async (e) => {
        e.preventDefault();
        let newDate = new Date(moment().subtract(1, 'day').format("MM/DD/YYYY"));
        this.setState({
            startDate: moment(newDate).format("MM/DD/YYYY") + " 00:00:00",
            endDate: moment(newDate).format("MM/DD/YYYY") + " 23:59:59",
        })
    }

    setLast7days = async (e) => {
        e.preventDefault();
        let newStartDate = new Date(moment().subtract(7, 'day').format("MM/DD/YYYY"));
        let newEndDate = new Date(moment().subtract(1, 'day').format("MM/DD/YYYY"));
        this.setState({
            startDate: moment(newStartDate).format("MM/DD/YYYY") + " 00:00:00",
            endDate: moment(newEndDate).format("MM/DD/YYYY") + " 23:59:59",
        });
    }

    render() {
        return (
            <Fragment>
                <DashboardComponent />

                <div className='filter-box'>
                    <div className="card p-1 m-1">
                        <div className='card-body p-2'>
                            <h5>Yatri Log</h5>
                            <hr className="col-lg-2 col-md-2 col-sm-2 col-xs-2" style={{ marginLeft: '0px' }} />
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <DateBox type="datetime"
                                            className="replay-date-time"
                                            name="startDate"
                                            placeholder="dd/MM/yyyy HH:mm:ss"
                                            displayFormat="dd/MM/yyyy HH:mm:ss"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleOnFilteFromDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                        <div style={{ width: '170px', marginTop: '10px' }}>
                                            <span><a href="#" onClick={(e) => { this.setToday(e) }}><b>Today</b></a></span>&nbsp;|&nbsp;
                                            <span><a href="#" onClick={(e) => { this.setYesterday(e) }}><b>Yesterday</b></a></span>&nbsp;|&nbsp;
                                            <span><a href="#" onClick={(e) => { this.setLast7days(e) }}><b>Last 7 Days</b></a></span>
                                        </div>
                                        {this.state.errors["dateRange"].length > 0 && <span className='error-msg'>{this.state.errors["dateRange"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="datetime"
                                            className="replay-date-time"
                                            name="endDate"
                                            placeholder="dd/MM/yyyy HH:mm:ss"
                                            displayFormat="dd/MM/yyyy HH:mm:ss"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleOnFilteToDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Select Vehicle</label>
                                        <SelectBox
                                            dataSource={this.state.vehicleList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Vehicle"
                                            showBorders={true}
                                            defaultValue={0}
                                            value={this.state.vehicleId}
                                            valueExpr='vehicleId'
                                            displayExpr='vehicleNo'
                                            onSelectionChanged={this.handleOnFilterVehicleChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                            width="100%"
                                        />
                                        {this.state.errors["vehicleId"].length > 0 && <span className="error-msg">{this.state.errors["vehicleId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" style={{ marginTop: 14 + 'px' }}>
                                    <button type="submit" className="btn btn-success" onClick={this.getYatriApiLog}>
                                        {this.state.isSubmited && <span title="In-process"><i className="fa fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Search"><i className="fa fa-search"></i> Search</span>}
                                    </button> &nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card p-1 m-1">
                    <div className="card-body p-2">
                        <DataGrid
                            dataSource={this.state.dataSourceYatriApiLog}
                            keyExpr="id"
                            selection={{ mode: "single" }}
                            columnsAutoWidth={true}
                            showColumnLines={true}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            allowColumnResizing={true}
                            columnResizingMode="widget"
                            width="100%"
                        >
                            <Column dataField="recordId" width={200} caption="Record" />
                            <Column dataField="deviceRegistrationId" width={200} caption="Device Imei" />
                            <Column dataField="vehicleNo" width={200} caption="Vehicle" />
                            <Column dataField="trackingTime" width={200} caption="Tracking Time" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" />
                            <Column dataField="serverDateTime" width={200} caption="Server Time" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" />
                            <Column dataField="sentDateTime" width={200} caption="Sent Time" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" />
                            <Column dataField="ackReceivedTime" width={200} caption="Ack Time" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" />
                            <Column dataField="trainNumber" width={150} caption="Train Number" />
                            <Column dataField="latitude" width={150} caption="Latitude" />
                            <Column dataField="longitude" width={150} caption="Longitude" />
                            <Column dataField="speed" width={150} caption="Speed" />
                            <Column dataField="stationCode" width={150} caption="StationCode" />
                            <Column dataField="nearestStationCode" width={85} caption="Nearest StationCode" />
                            <Column dataField="nearestStationPosition" width={150} alignment={'center'} caption="Nearest StationPosition" />
                            <Column dataField="trainStatus" width={150} alignment={'center'} caption="TrainStatus" />
                            <Column dataField="gpsStatus" width={150} caption="GpsStatus" />
                            <Column dataField="platformNo" width={50} caption="PF" />
                            <Column dataField="sentToApi" width={150} caption="SentToApi" />
                            <Column dataField="debugInfo" width={150} caption="DebugInfo" />
                            <Column dataField="apiResponse" width={150} caption="ApiResponse" />

                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={false} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                            <Export fileName='Yatri Log' enabled={true} allowExportSelectedData={false} />
                            <ColumnChooser enabled={false} />
                            <ColumnFixing enabled={true} />
                        </DataGrid>
                    </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </Fragment>
        );
    }
}
