import React, { Component, Fragment } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from "react-router-dom";
import secureLocalStorage from 'react-secure-storage';

export class DashboardComponent extends Component {
  static displayName = DashboardComponent.name;
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    this.state.errors[name] = "";
  }

  handleLogout = () => {
    secureLocalStorage.setItem("session_token", '');
    secureLocalStorage.setItem("session_userId", '');
    secureLocalStorage.setItem("session_userName", '');
    secureLocalStorage.setItem("session_name", '');
    secureLocalStorage.setItem("session_email", '');
    secureLocalStorage.setItem("session_accountId", '');
    secureLocalStorage.setItem("session_accountname", '');
    secureLocalStorage.setItem("session_locationId", '');
    secureLocalStorage.setItem("session_PageName", "Login");
    secureLocalStorage.setItem("session_stationCode", '');
    secureLocalStorage.setItem("session_stationName", '');
    secureLocalStorage.clear();
    window.location.href = "/login";
  }

  render() {
    return (
      <Fragment>
        <Navbar bg="dark" expand="lg" className='d-flex justify-content-between'>
          <Navbar.Brand href="#home"><img src="/img/logo-sst-white.png" alt="SilverStar" width="" height="" className='silverstarLogo' /></Navbar.Brand>
          <Nav.Link onClick={this.handleLogout} className='text-white p-0'>Logout</Nav.Link>
        </Navbar>
        <div className='title-station clearfix pt-2 pl-3 pb-2 pr-3'>
          <Link to="/yatriLog" style={{ color: 'white' }}><u>Yatri Log</u></Link>&nbsp;|&nbsp;
          <Link to="/trainMappingReport" style={{ color: 'white' }}><u>Train Mapping Report</u></Link>
        </div>
      </Fragment>
    );
  }
}
