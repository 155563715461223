import React, { Component, Fragment } from 'react';

import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid';

import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';
import { DashboardComponent } from '../components/DashboardComponent';
import { getRequestParams, formatDate } from '../../src/helpers/globalfunctions.js';
import secureLocalStorage from 'react-secure-storage';

const position = { of: '#historydiv' };
const currentDateTime  = new Date();
export class TrainMappingReportComponent extends Component {
    static displayName = TrainMappingReportComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            currentTime: moment().format("HH:mm A"),
            dataSource: [],
            accountId: secureLocalStorage.getItem("session_accountId"),
            isSubmited: false,
            loadPanelVisible: false,
            dataSourceTrainMappingReport: [],
            vehicleId: 0,
            startDate: moment(currentDateTime).format("MM/DD/YYYY") + " 00:00:00",
            endDate: moment(currentDateTime).format("MM/DD/YYYY") + " 23:59:59",
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        this.state.errors[name] = "";
    }

    getTrainMappingReport = async () => {
        try {
            this.setState({ isSubmited: true });
            const requestParams = getRequestParams('GET');
            let accountId = secureLocalStorage.getItem('session_accountId');
            let startDate = formatDate(this.state.startDate, "yyyy-MM-dd");
            let endDate = formatDate(this.state.endDate, "yyyy-MM-dd");
            this.setState({ loadPanelVisible: true });
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'TrainData/MLocalReport/' + accountId + "/" + startDate + "/" + endDate, requestParams);
            const data = await response.json();
            this.setState({
                dataSource: data.payload,
                loadPanelVisible: false,
                isSubmited: false
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getTrainMappingReport function", err);
            this.setState({ loadPanelVisible: false });
        }
    }

    handleResetStateData = (e) => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            vehicleId: 0,
            startDate: moment(currentDateTime).format("MM/DD/YYYY") + " 00:00",
            endDate: moment(currentDateTime).format("MM/DD/YYYY") + " 23:59",
            dataSourceTrainMappingReport: [],
        });
    }

    handleOnFilteFromDateChanged = (date) => {
        this.setState({ startDate: date.value });
    };
    handleOnFilteToDateChanged = (date) => {
        this.setState({ endDate: date.value });
    };

    render() {
        return (
            <Fragment>
                <DashboardComponent />

                <div className='filter-box'>
                    <div className="card p-1 m-1">
                        <div className='card-body p-2'>
                            <h5>Train Mapping Report</h5>
                            <hr className="col-lg-2 col-md-2 col-sm-2 col-xs-2" style={{ marginLeft: '0px' }} />
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="startDate"
                                            placeholder="dd/MM/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleOnFilteFromDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="replay-date-time"
                                            name="endDate"
                                            placeholder="dd/MM/yyyy"
                                            displayFormat="dd/MM/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleOnFilteToDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" style={{ marginTop: 14 + 'px' }}>
                                    <button type="submit" className="btn btn-success" onClick={this.getTrainMappingReport}>
                                        {this.state.isSubmited && <span title="In-process"><i className="fa fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Search"><i className="fa fa-search"></i> Search</span>}
                                    </button> &nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card p-1 m-1">
                    <div className="card-body p-2">
                        <DataGrid
                            dataSource={this.state.dataSource}
                            selection={{ mode: "single" }}
                            columnsAutoWidth={true}
                            showColumnLines={true}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            allowColumnResizing={true}
                            columnResizingMode="widget"
                            width="100%"
                        >
                            <Column dataField="reportDate" width={200} caption="Report Date" />
                            <Column dataField="lineCode" width={200} caption="Line Code" />
                            <Column dataField="direction" width={200} caption="Direction" />
                            <Column dataField="mappedTrains" width={200} caption="Mapped Trains" />
                            <Column dataField="totalTrains" width={200} caption="Total Trains" />
                            <Column dataField="mappingPct" width={200} caption="Mapping %" />

                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={false} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                            <Export fileName='Train Mapping Report' enabled={true} allowExportSelectedData={false} />
                            <ColumnChooser enabled={false} />
                            <ColumnFixing enabled={true} />
                        </DataGrid>
                    </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </Fragment>
        );
    }
}
